import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
import { Clip } from "../../components/clip";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div style={{
      display: "flex"
    }}>
    <Clip width="300px" src="/video/PR-01-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-02-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-03-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-04-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-05-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-06-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-07-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-08-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-09-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-10-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-11-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-12-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-13-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-14-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-15-540.mp4" loop mdxType="Clip" />
    <Clip width="300px" src="/video/PR-16-540.mp4" loop mdxType="Clip" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      